<template>
  <div>考试测试</div>
  <!-- <div class="adminClass">
    <el-radio-group v-model="radio" size="medium">
      <el-radio-button
        label="我的班级"
        class="navBtn"
        size="small"
        round
      ></el-radio-button>
      <el-radio-button
        label="作业"
        class="navBtn"
        size="small"
        round
      ></el-radio-button>
      <el-radio-button
        label="考试测试"
        class="navBtn"
        size="small"
        round
      ></el-radio-button>
    </el-radio-group>
  </div> -->
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.getChain();
    this.getUserList();
  },
};
</script>

<style lang="less" scoped></style>
